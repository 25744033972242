import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import ResponsiveImg from '../../components/ResponsiveImg';
import { openFindNeosurf, commonHeaderColor } from '../../util';


const PaysafeCardPage = ({ data }) => (
  <>
    <SEO
      title="Bon de Neosurf remplace Carte de Paysafe - Dépôts sécurisés"
      description="Achète un bon de Neosurf - un nouveau remplacement de la Carte de Paysafe. Les dépôts sûrs &amp; sécurisés sur des sites de jeux et de divertissement."
      alternateLanguagePages={[
        {
          path: 'paysafecard',
          language: 'en'
        },
        {
          path: 'paysafecard',
          language: 'fr'
        },
        {
          path: 'paysafecard',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.betbtc.io/sign-up/a48acf12" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.paysafeCard.childImageSharp.fluid} alt="Achète Neosurf &amp; paie en ligne en toute sécurité" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Le NeoSurf remplace désormais la Carte de Paysafe et il fonctionne parfaitement en Australie et dans le monde!</h1>
            <p className="textColorDark">As-tu rencontré des problèmes de dépôt avec la Carte de Paysafe récemment? Es-tu d’Australie et les transactions de Paysafe ne sont plus possibles en raison de nouvelles lois et législation?</p>
            <p className="textColorAccent bigger zeroMarginT"><em>Heureusement, il existe une solution!</em></p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller">FAITS MARQUANTS DU NEOSURF</h2>
            <ul>
              <li className="textColorDark">Exactement Le même Système de Bons que la Carte de Paysafe</li>
              <li className="textColorDark">Sûr &amp; sécurisé Pour les Transactions sur Internet</li>
              <li className="textColorDark">Achète le Neosurf dans plus de 135 000 endroits dans le monde</li>
            </ul>
          </div>
        </div>
        <div>
          <Img fluid={data.neosurf.childImageSharp.fluid} alt="Codes de Neosurf disponibles dans 135000 magasins dans le monde" />
        </div>
      </div>
    </ContentRow>
    <ContentRow
      backgroundColor="rgba(255,0,155, 0.8)"
      Img={
        <ResponsiveImg
          aspectRatio={data.findNeosurf.childImageSharp.fixed.aspectRatio}
          src={data.findNeosurf.childImageSharp.fixed.src}
          alt=""
        />
      }
      padV
    >
      <div className="contentBox textAlignCenter">
        <h2 className="smaller textColorComplementary">Trouve un détaillant de Neosurf près de chez toi</h2>
        <p className="textColorLight">Les clients aiment les bons de caisse de Neosurf car ils sont disponibles dans des milliers d’endroits pratiques à travers le monde. Aujourd’hui, tu trouveras des bons de caisse de Neosurf dans <strong className="textColorComplementary">les dépanneurs, les supermarchés, les stations-service, les kiosques, les cybercafés et plus d’autres</strong>.</p>
        <button className="button big" onClick={openFindNeosurf}>Où trouver le Neosurf?</button>
      </div>
    </ContentRow>
    <ContentRow backgroundColor="#eee">
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">PAIE &amp; JOUE EN LIGNE AVEC NEOSURF</h2>
            <p className="textColorDark">Ne souhaites-tu pas utiliser de cartes de débit / crédit pour payer en ligne? Ou alors, n’en as-tu même pas? Ne souhaites-tu peut-être pas fournir de coordonnées bancaires sensibles, car la confidentialité est ta préoccupation principale?</p>
            <p>Neosurf est probablement la <strong>solution idéale pour toi</strong>! Achète un bon de Neosurf en espèces et paie &amp; joue en ligne en toute sécurité. Simple que cela!  Regarde une courte vidéo expliquant le fonctionnement du système de bons de Neosurf.</p>
          </div>
        </div>
        <div>
          <div className="videoWrapper">
            <iframe
              title="neosurfVideo"
              src="https://www.youtube.com/embed/ZZKglPJVhFE"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textAlignCenter textUppercase">AS-TU DÉJÀ ACHETÉ UN BON?<br />REVIENS A LA CAISSE ET FAIS UN DÉPÔT!</h2>
      <div className="grid c-4 bigGap">
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.luckystar.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.betbtc.io/sign-up/a48acf12">
              <Img fluid={data.betbtc.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5">
              <Img fluid={data.lilly.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.777slotsbay.com/?goft=355fce1f-1701-4bc8-b29d-e3e0ec292198">
              <Img fluid={data.sevenSB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://www.777bay.com/?a_aid=5a6fb263dabe6">
              <Img fluid={data.sevenB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default PaysafeCardPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    paysafeCard: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "paysafecard/mac1.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 600,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    neosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "paysafecard/neosurf2_2.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    findNeosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "bg2.jpg"}) {
      childImageSharp {
        fixed(width: 524) {
          aspectRatio
          src
        }
      }
    }
    luckystar: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/lucky-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/betbtc-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lilly: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/lilly-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/emucasino-logo-bw.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777bay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenSB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777slotsbay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
